import { ActionIcon, Badge, Button, Card, Group, Modal, Stack, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Replace, X } from "tabler-icons-react";

export function TeleportLayerMoveButton({layer, layers}) {

    const queryClient = useQueryClient();

    const [opened, openedHandler] = useDisclosure(false);

    const moveLayer = useMutation((newParent) => {
        return BackendServiceV2.put(`/layer/${layer.id}/move`, {
            idParent: newParent.id
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-layer-data');
            openedHandler.close();

            showNotification({
                title: 'Layer movida com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const modals = useModals();
    const layerMoveConfirmation = (parent) => modals.openConfirmModal({
        title: 'Mover layer',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja mover a layer <b>{layer.name}</b>?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            moveLayer.mutate(parent);
        },
        zIndex: 1000
    });

    return (<>
        <Modal
            opened={opened}
            onClose={openedHandler.close}
            title={<Group><Replace /><Title order={2}>Mover</Title></Group>}
            centered
            size="lg"
        >
            <Stack spacing={5} style={{width: '100%'}}>

                {layers?.map(parent => (
                    <div key={layer.id}>
                        {parent.id !== layer.id &&
                            <Card withBorder>
                                <Group noWrap position="apart">
                                    <Group noWrap>
                                        <Badge style={{textTransform: 'none'}}>{parent.id}</Badge>
                                        <Text>{parent.name}</Text>
                                    </Group>

                                    <Group>
                                        <Button variant="subtle" onClick={e => {layerMoveConfirmation(parent)}}>Mover</Button>
                                    </Group>
                                </Group>
                            </Card>
                        }
                    </div>
                ))}
            </Stack>
        </Modal>

        <ActionIcon color="#ff5e00" variant="subtle" onClick={openedHandler.open} title="Mover"><Replace /></ActionIcon>
    </>)
}