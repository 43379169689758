import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';
import { ActionIcon, Button, Card, Checkbox, Group, LoadingOverlay, PasswordInput, Select, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import React, { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Refresh, X } from "tabler-icons-react";
import { TeleportLayerSalesMapDeleteButton } from '../TeleportLayerSalesMapDeleteButton';

export function TeleportLayerSalesMap({layer}) {

    const queryClient = useQueryClient();

    const form = useForm({
        initialValues: {
            provider: '',
            identifier: '',
            autoSync: true
        }
    });

    const credentialForm = useForm({
        initialValues: {
            instance: '', 
            endpoint: '', 
            email: '', 
            key: '', 
            token: ''
        }
    });

    const {data: salesMap, isLoading: isLoadingData} = useQuery(['teleport-layer-sales-map', layer.id], () => {
        return BackendServiceV2.get(`/layer/${layer.id}/salesmap/minimal`);
    });

    const {data: credential} = useQuery(['teleport-layer-sales-map-credential', salesMap?.id], () => {
        if(!salesMap?.id)
            return new Promise(() => {});

        return BackendServiceV2.get(`/salesmap/${salesMap?.id}/credential`);
    });

    const {data: integrationData, isLoading: isLoadingIntegrationData} = useQuery(['teleport-layer-sales-map-integration-data', layer.id], () => {
        return BackendServiceV2.get(`/layer/${layer.id}/salesmap/integration`);
    });
        
    useEffect(() => {
        form.setFieldValue('provider', salesMap?.provider || '');
        form.setFieldValue('identifier', salesMap?.providerIdentifier || '');
        form.setFieldValue('autoSync', salesMap?.autoSync);
    // eslint-disable-next-line
    }, [salesMap]);

    useEffect(() => {
        credentialForm.setFieldValue('instance', credential?.instance || '');
        credentialForm.setFieldValue('endpoint', credential?.endpoint || '');
        credentialForm.setFieldValue('email', credential?.email || '');
        credentialForm.setFieldValue('key', credential?.key || '');
        credentialForm.setFieldValue('token', credential?.token || '');
    // eslint-disable-next-line
    }, [credential]);

    const {mutate: createSalesMap} = useMutation(() => {
        return BackendServiceV2.post(`/layer/${layer.id}/salesmap`)
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-layer-sales-map');
            queryClient.invalidateQueries('teleport-layer-sales-map-integration-data');
            
            showNotification({
                title: 'Espelho de vendas criado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: false
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: false
            });
        }
    });

    async function processUpdate() {
        if(credential?.id === null || credential?.key) {
            updateOrCreateCredential(credentialForm.values);
        } else {
            updateSalesMap(form.values);
        }
    }

    const {mutate: updateOrCreateCredential} = useMutation((data) => {
        return BackendServiceV2.put(`/salesmap/${salesMap?.id}/credential`, data);
    }, {
        onSuccess: () => {
            updateSalesMap(form.values);
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const {mutate: updateSalesMap, isLoading: isLoadingUpdate} = useMutation((data) => {
        return BackendServiceV2.put(`/layer/${layer.id}/salesmap`, data);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-layer-sales-map');
            queryClient.invalidateQueries('teleport-layer-sales-map-integration-data');
            queryClient.invalidateQueries('teleport-layer-sales-map-credential');

            showNotification({
                title: 'Espelho de vendas atualizado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const {mutate: syncWithIntegration} = useMutation((idLayer) => {
        return BackendServiceV2.post(`/layer/${idLayer}/salesmap/sync`);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-sales-map');
            queryClient.invalidateQueries('teleport-layer-sales-map');
            queryClient.invalidateQueries('teleport-layer-sales-map-integration-data');

            showNotification({
                title: 'Sincronização iniciada com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (
        <SectionBox 
            title="Espelho de Vendas"
            right={<>
                <Group grow noWrap position="right" p={15}>
                    {salesMap?.syncing &&
                        <Text color="dimmed" size="sm" align="right">Sincronizando...</Text>
                    }

                    {salesMap && !salesMap?.syncing && <>
                        <TeleportLayerSalesMapDeleteButton idLayer={layer.id} />

                        {salesMap?.lastSync &&
                            <Text color="dimmed" size="xs" align="right">Última sincronização: {dayjs(salesMap?.lastSync).locale('pt-br').format('D [de] MMMM [as] HH:mm')}</Text>
                        }

                        <ActionIcon onClick={() => {syncWithIntegration(layer?.id)}}><Refresh color='#ff5e00' /></ActionIcon>
                    </>}
                </Group>
            </>}
        >
            <LoadingOverlay visible={isLoadingData || isLoadingUpdate || isLoadingIntegrationData} />

            {salesMap ? 
                <Stack>
                    <form onSubmit={form.onSubmit(values => processUpdate(values))}>
                        <Stack>
                            <Group noWrap grow>
                                <Select
                                    required
                                    label="Provedor"
                                    placeholder=""
                                    data={[
                                        {label: 'Órulo', value: 'ORULO'},
                                        {label: 'Facilita', value: 'FACILITA'},
                                        {label: 'CV', value: 'CV'},
                                        {label: 'iTeleport', value: 'ITELEPORT'},
                                    ]}
                                    {...form.getInputProps('provider')}
                                />

                                <TextInput
                                    required
                                    label={form.values.provider === 'ITELEPORT' ? 'Planilha' : 'ID'}
                                    {...form.getInputProps('identifier')}
                                />
                            </Group>

                            {credential?.key &&
                                <PasswordInput
                                    description="?sk=key para ativar edição direto no player"
                                    label="Key"
                                    {...credentialForm.getInputProps('key')}
                                />
                            }

                            {credential?.id === null &&
                                <Group noWrap grow>
                                    {form.values['provider'] === 'FACILITA' &&
                                        <TextInput
                                            required
                                            label="Instancia"
                                            {...credentialForm.getInputProps('instance')}
                                        />
                                    }

                                    {form.values['provider'] === 'CV' &&
                                        <TextInput
                                            required
                                            label="Endpoint"
                                            {...credentialForm.getInputProps('endpoint')}
                                        />
                                    }

                                    {form.values['provider'] === 'CV' &&
                                        <TextInput
                                            required
                                            label="E-mail"
                                            {...credentialForm.getInputProps('email')}
                                        />
                                    }

                                    {form.values['provider'] === 'FACILITA' &&
                                        <PasswordInput
                                            required
                                            label="Key"
                                            {...credentialForm.getInputProps('key')}
                                        />
                                    }

                                    {(form.values['provider'] === 'FACILITA' || form.values['provider'] === 'CV') &&
                                        <PasswordInput
                                            required
                                            label="Token"
                                            {...credentialForm.getInputProps('token')}
                                        />
                                    }
                                </Group>
                            }

                            <Checkbox
                                label="Sincronizar diariamente"
                                description="Sincroniza exibição com espelho diariamente"
                                name="autoSync"
                                size="md"
                                checked={form.getInputProps('autoSync').value}
                                {...form.getInputProps('autoSync')}
                            />

                            <Button type="submit">Salvar</Button>
                        </Stack>
                    </form>

                    {integrationData &&
                        <Group style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))', textAlign: 'center'}}>
                            {integrationData?.units?.map(unit => (
                                <Card withBorder p={8} style={{backgroundColor: unit?.availability === 'AVAILABLE' ? 'lightgreen' : 'lightgray'}} key={"T" + unit.tower + '_' + unit.number + '_1'}>
                                    <Text size="xs">T{unit.tower + '_' + unit.number + '_1'}</Text>
                                </Card>
                            ))}
                        </Group>
                    }
                </Stack>
            :
                <Button onClick={createSalesMap}>Criar espelho de vendas</Button>
            }
        </SectionBox>
    )
}