import { Badge, Button } from "@mantine/core";
import { FixedHeightContentWrapper, FixedHeightLeftContent, FixedHeightRightContent } from "components/v2/Dashboard/Layout";
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";
import React, { useContext, useEffect, useState } from "react";
import { TeleportLayerCreateAndEdit } from "../TeleportLayerCreateAndEdit";
import { TeleportLayerCreateLayerChildButton } from "../TeleportLayerCreateChildButton";
import { TeleportLayerDeleteButton } from "../TeleportLayerDeleteButton";
import { TeleportLayerList } from "../TeleportLayerList";
import { TeleportLayerProcessing } from "../TeleportLayerProcessing";
import { TeleportLayerSkyboxes } from "../TeleportLayerSkyboxes";
import { TeleportLayerProcessingRequests } from "../TeleportLayerProcessingRequests";
import { TeleportLayerSalesMap } from "../TeleportLayerSalesMap";
import { TeleportLayerMoveButton } from "../TeleportLayerMoveButton";

export function TeleportLayerContent({idTeleport, layers}) {

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    // ACTIVE LAYER
    const [layerList, setLayerList] = useState();
    const [activeLayer, setActiveLayer] = useState();
    const [mainLayer, setMainLayer] = useState();

    useEffect(() => {
        if(!layers || !layers[0])
            return;

        if(!activeLayer)
            setActiveLayer(layers[0]);

        setMainLayer(layers[0]);
        setLayerList(layers);
    // eslint-disable-next-line
    }, [layers]);

    return (
        <FixedHeightContentWrapper>
            <FixedHeightLeftContent title="Layers">
                {mainLayer && <TeleportLayerList layer={mainLayer} setActiveLayer={setActiveLayer} moveable={false} key={layerList.length} />}
            </FixedHeightLeftContent>

            {activeLayer && 
                <FixedHeightRightContent 
                    title={activeLayer.name}
                    badges={<Badge>{activeLayer.id}</Badge>}
                    right={<>
                        <Button variant="subtle" component="a" href="https://whimsical.com/layers-JGNpoofu1ChyndV5ZzmYg6" target="_blank">Aprenda a enviar arquivos</Button>
                        {activeLayer?.id !== mainLayer?.id && <TeleportLayerMoveButton layer={activeLayer} layers={layerList} />}
                        <TeleportLayerDeleteButton layer={activeLayer} />
                        <TeleportLayerCreateLayerChildButton idTeleport={idTeleport} layer={activeLayer} />
                    </>}
                >
                    {resourcePermission.containsPermission('TELEPORT_LAYER_UPDATE') && activeLayer.type !== 'GROUP' && 
                        <TeleportLayerProcessing layer={activeLayer} />
                    }

                    {resourcePermission.containsPermission('TELEPORT_LAYER_UPDATE') && activeLayer.type !== 'GROUP' && 
                        <TeleportLayerProcessingRequests layer={activeLayer} />
                    }

                    {resourcePermission.containsPermission('TELEPORT_LAYER_UPDATE') && activeLayer.type !== 'GROUP' && 
                        <TeleportLayerSalesMap layer={activeLayer} idTeleport={idTeleport} />
                    }

                    {resourcePermission.containsPermission('TELEPORT_LAYER_UPDATE') &&
                        <TeleportLayerCreateAndEdit updateLayerData={activeLayer} />
                    }

                    {resourcePermission.containsPermission('TELEPORT_LAYER_READ') && activeLayer.type !== 'GROUP' && 
                        <TeleportLayerSkyboxes layer={activeLayer} />
                    }
                </FixedHeightRightContent>
            }
        </FixedHeightContentWrapper>
    )
}