import React, {useContext} from 'react';
import { SectionBox } from 'components/v2/SectionBox';
import { SimpleGrid, Text } from '@mantine/core';
import { LayerSkyboxCard } from './LayerSkyboxCard';
import { ResourcePermissionContext } from 'context/Permission/ResourcePermissionContext';
import { TeleportLayerSkyboxesDeleteButton } from './LayerSkyboxesDeleteButon';

export function TeleportLayerSkyboxes({layer}) {

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    return (<>
        {resourcePermission.containsPermission('TELEPORT_LAYER_READ') &&
            <SectionBox 
                title="Skyboxes" 
                description="Configurações das skyboxes da layer"
                right={layer.skyboxes.length > 0 && <TeleportLayerSkyboxesDeleteButton idLayer={layer.id} />}
            >
                <SimpleGrid cols={3}>
                    {layer?.skyboxes?.map(skybox => (
                        <LayerSkyboxCard key={skybox.id} skybox={skybox} />
                    ))}
                </SimpleGrid>

                {(!layer.skyboxes || layer.skyboxes.length === 0) && <Text color="dimmed" size="xs" align="center">Nenhuma skybox para exibir</Text>}
            </SectionBox>
        }
    </>);
}