import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";
import { Container, Group, LoadingOverlay, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React, { useState } from "react";
import { Check, ChevronDown, GripVertical, LayersSubtract, X } from "tabler-icons-react";
import {CSS} from '@dnd-kit/utilities';
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { showNotification } from "@mantine/notifications";

export function TeleportLayerList({layer, setActiveLayer, moveable = true}) {
    
    const queryClient = useQueryClient();

    const [showChilds, showChildsHandler] = useDisclosure(true);

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: layer?.id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        padding: '8px 0'
    };

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    )

    const [sortableItems, setSortableItems] = useState(layer?.childs?.map(entry => (entry?.id)))

    function onDragEnd(event) {
        const {active, over} = event;

        if (active.id !== over.id) {
            setSortableItems((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);
                return arrayMove(items, oldIndex, newIndex);
            });

            updateLayerOrder({idLayer: active.id, order: over.data.current.sortable.index + 1});
        }
    }

    const {mutate: updateLayerOrder, isLoading: isLayerOrderLoading} = useMutation((data) => {
        console.log("SETTING TO ORDER " + data.order);
        return BackendServiceV2.put('/layer/' + data.idLayer, {
            order: data.order
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-layer-data');
            queryClient.invalidateQueries('teleport-data');
            
            showNotification({
                title: 'Layer atualizada com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    })

    return (
        <>
            <LoadingOverlay visible={isLayerOrderLoading} />
            
            <Group
                onClick={e => {setActiveLayer(layer)}}
                className="draggrable-card" 
                ref={setNodeRef} 
                style={style} 
                {...attributes}
                position="apart"
                noWrap
            >
                <Group spacing={3} noWrap>  
                    {layer?.childs?.length > 0 ? <ChevronDown onClick={showChildsHandler.toggle} /> : <LayersSubtract onClick={showChildsHandler.toggle} />}
                    <Text lineClamp={1}>{layer?.name}</Text>
                </Group>
                    
                {moveable &&
                    <GripVertical {...listeners} color="gray" cursor="grab" />
                }
            </Group>
            
            <Container pl="25px" pr={0}>
                <DndContext collisionDetection={closestCenter} sensors={sensors} onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]}>
                    <SortableContext items={sortableItems}>
                            {layer?.childs?.length > 0 && showChilds && <>
                                {sortableItems?.map((idChild) => {
                                    let child = layer?.childs?.find(child => child?.id === idChild);

                                    if(!child) return <></>

                                    return (
                                        <TeleportLayerList key={child.id} layer={child} setActiveLayer={setActiveLayer} />
                                    )
                                })}
                            </>}
                    </SortableContext>
                </DndContext>
            </Container>
        </>
    )
}