import React, {useContext} from 'react';
import { ContentWrapper } from 'components/v2/Dashboard/Layout';
import BackendServiceV2 from 'services/v2/BackendService';
import { useQuery } from 'react-query';
import { TeleportLayerContent } from './TeleportLayerContent';
import { TeleportLayerCreateAndEdit } from './TeleportLayerCreateAndEdit';
import { LoadingOverlay } from '@mantine/core';
import { ResourcePermissionContext } from 'context/Permission/ResourcePermissionContext';

export function TeleportLayer({ idTeleport }) {

    // PERMISSIONS
    const resourcePermission = useContext(ResourcePermissionContext);

    // LAYERS
    const {data: layers, isLoading} = useQuery(['teleport-layer-data', idTeleport], () => {
        return BackendServiceV2.get(`/teleport/${idTeleport}/layer`);
    });

    if(isLoading) return (
        <ContentWrapper>
            <LoadingOverlay visible={isLoading} />
        </ContentWrapper>
    )

    return (<>
        {resourcePermission.containsPermission('TELEPORT_LAYER_READ') && <>
            {resourcePermission.containsPermission('TELEPORT_LAYER_CREATE') && (!layers || layers?.length === 0) && 
                <ContentWrapper>
                    <TeleportLayerCreateAndEdit idTeleport={idTeleport} />
                </ContentWrapper>
            }

            {layers && layers?.length !== 0 && 
                <TeleportLayerContent idTeleport={idTeleport} layers={layers}></TeleportLayerContent>
            }
        </>}
    </>)
}