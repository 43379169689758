import { Button, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";

export function TeleportLayerSkyboxesDeleteButton({idLayer}) {
    
    const queryClient = useQueryClient();

    const modals = useModals();

    const deleteObject = useMutation(() => {
        return BackendServiceV2.delete('/layer/' + idLayer + '/skyboxes')
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-layer-data');

            showNotification({
                title: 'Skyboxes deletada com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const deleteSkyboxesConfirmationModal = () => modals.openConfirmModal({
        title: 'Deletar skyboxes',
        centered: true,
        children: (
            <Text size="sm">
                Você tem certeza que deseja deletar as <b>skyboxes</b>?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            deleteObject.mutate();
        }
    });

    return (
        <Button variant="subtle" onClick={deleteSkyboxesConfirmationModal}>Deletar skyboxes</Button>
    )
}